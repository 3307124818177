<!--
 * @Description: 渠道分析
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-14 18:00:22
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-12 16:26:09
-->
<template>
  <div class="channel">
    <div class="header" style="text-align: center;">
       <a-tabs
        v-model:activeKey="currentTab" 
        type="card"
      >
        <a-tab-pane key="1" tab="来源渠道">
        </a-tab-pane>
        <a-tab-pane key="2" tab="访问时间">
        </a-tab-pane>
        <a-tab-pane key="3" tab="地理位置">
        </a-tab-pane>
      </a-tabs>
      <!-- <div class="center-top">
        <div :class="{'active': currentTab === 1}" @click="tabSwitch(1)">来源渠道</div>
        <div :class="{'active': currentTab === 2}" @click="tabSwitch(2)">访问时间</div>
        <div :class="{'active': currentTab === 3}" @click="tabSwitch(3)">地理位置</div>
      </div> -->
    </div>
    <div class="content">
      <div class="date-picker" v-if="currentTab === '2'">
        <p>时间段: </p>
        <a-range-picker class="date" v-model:value="time">
          <template #suffixIcon>
            <span class="iconfont">&#xe632;</span>
          </template>
        </a-range-picker>
      </div>
      <div class="table">
        <a-table :columns="columns" :data-source="data.value"/>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity';
import { getQuestionnaireSourceSummary, getQuestionnarieTimeSummary, getQuestionnarieLocationSummary } from '@/api/questionnaire'
import { onMounted, watch } from '@vue/runtime-core';
import { message } from 'ant-design-vue'
import { useRoute} from 'vue-router'

const columns = reactive([
  {
    title: '来源渠道',
    dataIndex: 'source',
  },
  {
    title: '访问次数',
    dataIndex: 'accessNum',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.accessNum - b.accessNum,
  },
  {
    title: '访问人数',
    dataIndex: 'accessUsers',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.accessUsers - b.accessUsers,
  },
  {
    title: '有效问卷数量',
    dataIndex: 'validQuestionnaireNum',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.validQuestionnaireNum - b.validQuestionnaireNum,
  },
  {
    title: '完成率',
    dataIndex: 'completeRate',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.completeRate - b.completeRate,
  },
  {
    title: '有效数量渠道占比',
    dataIndex: 'validRate',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.validRate - b.validRate,
  }
])

const apiList = [
  getQuestionnaireSourceSummary,
  getQuestionnarieTimeSummary,
  getQuestionnarieLocationSummary,
]

const handleColumns = (currentTab, columns) => {
  columns.shift()
  switch (currentTab.value) {
    case '1': {
      columns.unshift({
        title: '来源渠道',
        dataIndex: 'source',
      })
      break;
    }
    case '2': {
      columns.unshift({
        title: '日期',
        dataIndex: 'timeDay',
      })
      break
    }
    case '3': {
      columns.unshift({
        title: '地址',
        dataIndex: 'location',
      })
      break
    }
    default: break
  }
}

const dealData = (inputData, data) => {
  // 增加table的key值
  let index = 1;
  data.value = inputData.map((item) => {
    item.key = index++
    item.completeRate = parseInt(item.completeRate) * 100 + '%'
    item.validRate = parseInt(item.validRate) * 100 + '%'
    return item
  })
}

export default {
  setup () {
    const route = useRoute()
    const id = route.params.id
    const time = ref([])
    const currentTab = ref('1')
    const data = reactive([])

    watch(currentTab, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        
        handleColumns(currentTab, columns)
        const apiFunc = apiList[parseInt(currentTab.value) - 1]
        if (currentTab !== '2') {
          const result = await apiFunc(id)
          if (result.status === 200 && result.data && result.data.code === '00000') {
            dealData(result.data.data, data)
          }
        }
      }
    })

    watch(time, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        const startTime = time.value[0].format('YYYY-MM-DD')
        const endTime = time.value[1].format('YYYY-MM-DD')
        if (startTime === undefined || endTime === undefined) {
          return
        }
        const result = await getQuestionnarieTimeSummary(id, startTime, endTime)
        if (result.status === 200 && result.data && result.data.code === '00000') {
          dealData(result.data.data, data)
        }
      }
    })

    const getQuestionnarieSummaryAsync = async () => {
      const result = await getQuestionnaireSourceSummary(id)
      if (result.status === 200 && result.data && result.data.code === '00000') {
        dealData(result.data.data, data)
      }
    }

    onMounted(getQuestionnarieSummaryAsync)

    const tabSwitch = (index) => {
      currentTab.value = index
    }

    return {
      currentTab,
      data,
      time,

      columns,
      tabSwitch
    }
  }
}
</script>
<style lang="less" scoped>
.channel {
  width: 100%;
  background: #fff;
  .content {
    width: 100%;
    .date-picker {
      display: flex;
      margin-left: 50px;
      height: 100%;
      p {
        font-size: 20px;
        line-height: 62px;
        padding-right: 10px;
      }
      .date {
        padding: 10px 0 32px 0;
        ::v-deep(.ant-input) {
          line-height: 32px;
        }
        ::v-deep(input) {
          height: 32px;
          line-height: 32px;
          font-size: 16px;
          color: #7F7F88;
          border-bottom: none;
        }
        .iconfont {
          position: absolute;
          top: 5px;
        }
      }
    }
    
    .table {
      display: flex;
      justify-content: center;
      margin: 0 50px;
      font-size: 16px;
      .ant-table-wrapper {
        width: 100%;
        ::v-deep(.ant-table-body) {
          font-size: 16px;
        }
      }
    }
  }
}
</style>